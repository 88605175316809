import { getAuth, signInWithPhoneNumber, RecaptchaVerifier, signInWithEmailAndPassword, sendSignInLinkToEmail } from "firebase/auth";
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { sendResetPasswordEmail, checkMatchingParentAPI } from '../api/api';
import useFeathers from "./useFeathers";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const hookFeathers = useFeathers();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const sendCode = async ({ sPhoneNumber, handleSuccess, handleError }) => {
        try {
            const auth = getAuth();

            // ✅ Remove existing reCAPTCHA container to force re-render
            let recaptchaWrapper = document.getElementById("recaptcha-wrapper");
            if (!recaptchaWrapper) {
                recaptchaWrapper = document.createElement("div");
                recaptchaWrapper.id = "recaptcha-wrapper";
                document.body.appendChild(recaptchaWrapper);
            }
            recaptchaWrapper.innerHTML = `<div id="recaptcha-container"></div>`; // Reset container

            // ✅ Destroy previous reCAPTCHA instance completely
            if (window.recaptchaVerifier) {
                try {
                    window.recaptchaVerifier.clear();
                } catch (error) {
                    console.warn("⚠ reCAPTCHA already cleared, continuing...");
                }
                window.recaptchaVerifier = null;
            }

            // ✅ Initialize a new RecaptchaVerifier instance
            window.recaptchaVerifier = new RecaptchaVerifier(
                auth,
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: (response) => {
                        console.log("✅ reCAPTCHA Verified:", response);
                    },
                }
            );

            const appVerifier = window.recaptchaVerifier;

            // ✅ Send OTP using Firebase Auth
            const confirmationResult = await signInWithPhoneNumber(auth, sPhoneNumber, appVerifier);
            handleSuccess(confirmationResult);
        } catch (error) {
            console.error("❌ Error Sending Code:", error);
            handleError(error);
        }
    };


    const sendOTP = async (sPhoneNumber) => {
        try {
            const auth = getAuth();

            // ✅ Remove existing reCAPTCHA container to force re-render
            let recaptchaWrapper = document.getElementById("recaptcha-wrapper");
            if (!recaptchaWrapper) {
                recaptchaWrapper = document.createElement("div");
                recaptchaWrapper.id = "recaptcha-wrapper";
                document.body.appendChild(recaptchaWrapper);
            }
            recaptchaWrapper.innerHTML = `<div id="recaptcha-container"></div>`; // Reset container

            // ✅ Destroy previous reCAPTCHA instance completely
            if (window.recaptchaVerifier) {
                try {
                    window.recaptchaVerifier.clear();
                } catch (error) {
                    console.warn("⚠ reCAPTCHA already cleared, continuing...");
                }
                window.recaptchaVerifier = null;
            }

            // ✅ Initialize a new RecaptchaVerifier instance
            window.recaptchaVerifier = new RecaptchaVerifier(
                auth,
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: (response) => {
                        console.log("✅ reCAPTCHA Verified:", response);
                    },
                }
            );

            const appVerifier = window.recaptchaVerifier;

            // ✅ Send OTP using Firebase Auth
            return await signInWithPhoneNumber(auth, sPhoneNumber, appVerifier);
        } catch (error) {
            console.error("❌ Error Sending OTP:", error);
            throw error; // Return error so it can be handled by the caller
        }
    };


    const signIn = async (e, verificationCode, confirmationResult) => {
        return confirmationResult.confirm(verificationCode);
    };

    const loginWithEmailAndPassword = async (email, password) => {
        try {
            const auth = getAuth();
            const result = await signInWithEmailAndPassword(auth, email, password);
            return result;
        } catch (error) {
            console.log(error)
        }

    }

    const checkExistance = async (phonenumber, username) => {
        await hookFeathers.loginAnonymously();
        try {
            const matchingParent = await checkMatchingParentAPI({ phonenumber, username });
            return matchingParent;
        } catch (error) {
            throw error;
        } finally {
            await hookFeathers.logoutAnonymously();
        }
    }

    const SendPasswordReset = async (email) => {
        try {
            const data = { email };
            await sendResetPasswordEmail(data)
            enqueueSnackbar(`${t("resetPassMail")} ${email}`, { style: { backgroundColor: "#ff6645 !important" } });
        } catch (error) {
            enqueueSnackbar(error);
        }
    }
    const sendSignInEmail = async (id, email, phoneNumber) => {
        const auth = getAuth();
        const actionCodeSettings = {
            url: `${window.location.origin}/fbUserAction?idForSignIn=${id}_${phoneNumber}`,
            handleCodeInApp: true,
        };

        try {
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);

            const maskEmail = (email) => {
                const [username, domain] = email.split("@");
                if (!username || !domain) return email;
                const maskedUsername = username[0] + "***";
                return `${maskedUsername}@${domain}`;
            };

            window.localStorage.setItem('emailForSignIn', email);
            enqueueSnackbar(`${t("emailVerTitle1")} ${maskEmail(email)}`, { variant: "success" });

            return { sucess: true, maskMail: maskEmail(email) };
        } catch (error) {
            console.log(error);
            return { sucess: false };
        }
    };

    const parseFirebaseError = (error) => {
        let msg = "";
        if (error?.code) { //firebase errors
            switch (error.code) {
                case "auth/invalid-verification-code":
                    msg = t("invalidVerificationCode")
                    break;
                case "auth/missing-verification-code":
                    msg = t("missingVerificationCode")
                    break;
                case "auth/code-expired":
                    msg = t("expiredVerificationCode")
                    break;
                case "auth/too-many-requests":
                    msg = t("tooManyPhoneRequests")
                    break;
                default:
                    break;
            }
        }

        if (!msg) {
            msg = error?.message || t("Error");
        }

        return msg;

    }

    return {
        sendCode,
        sendOTP,
        signIn,
        loginWithEmailAndPassword,
        checkExistance,
        SendPasswordReset,
        sendSignInEmail,
        parseFirebaseError
    };
};


