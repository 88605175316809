import { useEffect, useRef, useState } from 'react';
import { useStyles } from '../assets/LoginStyle.jsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useGlobal from 'store/store.js';
import useVerification from '../hooks/useVerification.js';
//mui
import { Box, Button, Card, Container, Tab, Tabs, Typography } from '@mui/material';
//libs
import { Animated } from "react-animated-css";
import { useNavigate } from 'raviger';
// import Quack from '@thequack/sdk';
//assets
import loginBg from '../assets/loginBg.png';
import wrongSmsCode from '../assets/wrongSmsCode.svg';
import MailSuccess from '../assets/MailSuccess.svg';
//components
import { VBox } from '../assets/SharedComponents';
import { IdPassProcess } from '../Components/Login/IdPassProcess.jsx';
import { SmsProcess } from '../Components/Login/SmsProcess.jsx';
import { Office365Tab } from '../Components/Login/Office365Tab.jsx';
import { RegisterStepper } from 'Components/Register/RegisterStepper.jsx';
import LoginPageHeadr from 'Components/Login/LoginPageHeadr.jsx';
import cantFindSVG from '../assets/cantFindSVG.svg';
import EmailTakenModal from 'Components/Login/EmailTakenModal.jsx';
import EmailEmptyModal from 'Components/Login/EmailEmptyModal.jsx';
//api
import GenericDialog from 'Components/Common/GenericDIalog.jsx';

export const Login = ({ guest }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const hookVerification = useVerification();
    const navigate = useNavigate();
    const [PhoneNumber, setPhoneValue] = useState({ phone: "", fixedNumber: "", confirmationResult: {} });
    const [Verification, setVerificationProcess] = useState({ level: 1 });  // sms
    const [tabValue, setValue] = useState(1); // id and pass
    const [tribeUserTab, setTribeUserTab] = useState(false);
    const [busy, setBusy] = useState(false);
    const [noFoundParent, setNoFoundParents] = useState(false);
    const [errorDetails, setErrorDetails] = useState({ username: "", phone: "" });
    const [username, setUsername] = useState("");
    const [smsSendError, setSmsSendError] = useState(false);
    const [emailMask, setEmailMask] = useState("");
    const [emailSendedDialog, setEmailSendedDialog] = useState(false);
    const [LoginIdProcess, setLoginIdProcess] = useGlobal(state => state.LoginIdProcess, actions => actions.setLoginIdProcess);
    const [emailExsist, setEmailExsist] = useGlobal(state => state.emailExsist, actions => actions.setEmailExsist);
    const [emailEmpty, setEmailEmpty] = useGlobal(state => state.emailEmpty, actions => actions.setEmailEmpty);

    useEffect(() => {
        setLoginIdProcess(1);
    }, [tabValue]);

    // const submitForm = async (props) => {
    //     try {
    //         const data = {
    //             TribeId: props.TribeId,
    //             TribeName: props.TribeName,
    //             ParentName: props.ParentName,
    //             ChildName: props.ChildName,
    //             ChildID: props.ChildID,
    //             Phone: props.Phone,
    //             Mail: props.Mail,
    //             ReasonName: "דיווח באג",
    //             LongText: props.LongText,
    //             solvedBtn: `${window.location.origin}/QuackPage?quackUrl=${props.solvedBtn}`,
    //             escalateBtn: `${window.location.origin}/QuackPage?quackUrl=${props.escalateBtn}`
    //         };
    //         await hookFeathers.loginAnonymously();
    //         const res = await contactTribe(data);
    //         await hookFeathers.logoutAnonymously();

    //     } catch (error) {
    //         console.log(error)
    //         enqueueSnackbar(t("errorOccuredText"), { className: classes.redBG });
    //     }
    // }

    // global func to  show MessageBox
    const handleNoMatchingParentFound = (username, phone) => {
        setVerificationProcess({ level: 1 });
        setErrorDetails({ username, phone });
        setNoFoundParents(true);
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setVerificationProcess({ level: 1 });
    };
    // smsTab
    const handleVerificationProcess = async (e) => {
        if (Verification.level === 1) { // sending code to phone
            let sPhoneNoHyphen = PhoneNumber['phone'].replaceAll("-", "");
            const sPhoneNumber = "+972" + (sPhoneNoHyphen[0] === '0' ? sPhoneNoHyphen.slice(1, sPhoneNoHyphen.length) : sPhoneNoHyphen);
            setPhoneValue({ ...PhoneNumber, fixedNumber: sPhoneNumber, confirmationResult: {} });

            const handleSuccess = (confirmationResult) => {
                setVerificationProcess((obj) => ({ 'level': obj.level + 1 }));
                setPhoneValue({ ...PhoneNumber, confirmationResult: confirmationResult });
                setBusy(false);
            }

            const handleError = (error) => {
                setSmsSendError(true);
                const msg = hookVerification.parseFirebaseError(error);
                enqueueSnackbar(msg, { className: classes.redBG });
                setBusy(false);
            }
            setBusy(true);
            try {
                //sign anonymously to check if username(id num) and phone exists in system
                const resultCheck = await hookVerification.checkExistance(sPhoneNumber, username);
                //send verification code to phone
                hookVerification.sendCode({ sPhoneNumber, handleSuccess, handleError });

            } catch (error) {//no matching parent for id and phone number
                setBusy(false);
                if (error.data && error.data.noMatchingParentFound) {
                    handleNoMatchingParentFound(error.data.username, error.data.phone);
                    console.log(error);
                }
                else {
                    const msg = hookVerification.parseFirebaseError(error);
                    enqueueSnackbar(msg, { className: classes.redBG });
                }
            }
        }
        else {
            setVerificationProcess((obj) => ({ 'level': obj.level + 1 }));
        }
    };
    const loginUserByEmail = async () => {
        try {
            setBusy(true);
            let sPhoneNoHyphen = PhoneNumber['phone'].replaceAll("-", "");
            const sPhoneNumber = "+972" + (sPhoneNoHyphen[0] === '0' ? sPhoneNoHyphen.slice(1, sPhoneNoHyphen.length) : sPhoneNoHyphen);
            const resultCheck = await hookVerification.checkExistance(sPhoneNumber, username);
            const userMail = resultCheck.matchingParent[`Parent${resultCheck.idx}_Mail`];
            const isMailSentSuccsfully = await hookVerification.sendSignInEmail(username, userMail, sPhoneNumber);
            setEmailMask(isMailSentSuccsfully.maskMail);
            setEmailSendedDialog(isMailSentSuccsfully.sucess);
            setBusy(false);
        } catch (error) {
            setBusy(false);
            console.log(error);
        }
    };
    const handleLinkFromDialog = () => {
        loginUserByEmail();
        setSmsSendError(false);
    }

    const tabs = [t("idAndPass"), t("smsOTP")];
    const containerSlideRef = useRef(null);

    const changePhoneValue = (e, sProp) => {
        if (sProp === "confirmationResult") {
            setPhoneValue({ ...PhoneNumber, confirmationResult: e });
        }
        else {
            const value = e.target.value;
            setPhoneValue({ ...PhoneNumber, [sProp]: value });
        }
    }

    const backToSMS = (e) => {
        handleChange(e, 1);
        setTribeUserTab(false);
    }
    const tribeTab = (e) => {
        handleChange(e, 2);
        setTribeUserTab(true);
    }
    const guestSteps = [t("phoneVerification"), t("watchActivity"), t("payment")];
    const sxProps = {
        backgroundImage: `url(${loginBg})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }
    return (
        <>
            <Box className={`${classes.height100} ${classes.AppBackground}`} style={{ minHeight: '50rem' }}>
                <LoginPageHeadr tribeTab={tribeTab} tribeUserTab={tribeUserTab} backToSMS={backToSMS} guest={guest} />
                <Box alignItems="center" display="flex" flexDirection="column" >
                    {guest ?
                        <>
                            <RegisterStepper aSteps={guestSteps} activeStep={1} guest={true} />
                            <Card className={classes.loginCard}>
                                <Box sx={{ height: { xs: '55%', lg: '60%', xl: '60%' }, marginTop: "3rem" }} className={`${classes.margin0_1} ${classes.SmsMinHeight} ${classes.guestLoginStyle}`} >
                                    <VBox ref={containerSlideRef} className={classes.TabHeigth} value={1} index={1} style={{ textAlign: "center", alignItems: "center", marginTop: "3rem" }}>
                                        <SmsProcess
                                            handleNoMatchingParentFound={handleNoMatchingParentFound}
                                            changePhoneValue={changePhoneValue}
                                            PhoneNumber={PhoneNumber}
                                            username={username}
                                            setUsername={setUsername}
                                            containerSlideRef={containerSlideRef}
                                            handleVerificationProcess={handleVerificationProcess}
                                            Verification={Verification}
                                            busy={busy}
                                            loginUserByEmail={loginUserByEmail}
                                        />
                                    </VBox>
                                </Box>
                            </Card>
                        </>
                        : <>
                            {!tribeUserTab && <Tabs
                                centered
                                className={`${classes.TabStyle} ${classes.SelectedTab}`}
                                defaultValue={0}
                                value={tabValue}
                                indicatorColor="transparent"
                                onChange={handleChange}>
                                {tabs.map((tab, i) => <Tab label={tab} key={i} />)}
                            </Tabs>}
                            <Card className={classes.loginCard} sx={Verification.level === 1 && !tribeUserTab && LoginIdProcess === 1 ? sxProps : { background: "#fffff" }}>
                                <>
                                    <Animated animationOut="fadeInDown" animationOutDuration={3500} isVisible={true}>
                                        <Box sx={{ height: { xs: '55%', lg: '50%', xl: '50%' } }} className={`${classes.margin0_1} ${classes.SmsMinHeight}`} >
                                            <Box ref={containerSlideRef} className={classes.FatherTabsStyle}>
                                                {/* Login with id and password Tab */}
                                                <TabPanel className={classes.TabHeigth} value={tabValue} index={0} >
                                                    <Container>
                                                        <IdPassProcess
                                                            handleNoMatchingParentFound={handleNoMatchingParentFound}
                                                            changePhoneValue={changePhoneValue}
                                                            PhoneNumber={PhoneNumber}
                                                            containerSlideRef={containerSlideRef}
                                                        />
                                                    </Container>
                                                </TabPanel>
                                                {/*Login with SMS Tab */}
                                                <TabPanel className={classes.TabHeigth} value={tabValue} index={1} >
                                                    <Container>
                                                        <SmsProcess
                                                            handleNoMatchingParentFound={handleNoMatchingParentFound}
                                                            changePhoneValue={changePhoneValue}
                                                            PhoneNumber={PhoneNumber}
                                                            username={username}
                                                            setUsername={setUsername}
                                                            containerSlideRef={containerSlideRef}
                                                            handleVerificationProcess={handleVerificationProcess}
                                                            Verification={Verification}
                                                            busy={busy}
                                                            loginUserByEmail={loginUserByEmail}
                                                        />
                                                    </Container>
                                                </TabPanel>
                                                {/*Login with office365 tribe user */}
                                                <TabPanel mt={{ md: '1rem' }} className={classes.TabHeigth} value={tabValue} index={2} >
                                                    <Container>
                                                        <Office365Tab handleChange={handleChange} setTribeUserTab={setTribeUserTab} />
                                                    </Container>
                                                </TabPanel>
                                            </Box>
                                        </Box>
                                        {!tribeUserTab && tabValue < 2 && Verification.level === 1 && LoginIdProcess !== 3 && LoginIdProcess !== 2 && <Box sx={{ height: '5%' }}><ActionButtonRegister /></Box>}
                                    </Animated>
                                </>
                            </Card>
                        </>}
                </Box>
                {/* <LoginVerification /> */}
            </Box >
            <EmailTakenModal open={emailExsist} setOpen={setEmailExsist} PhoneNumber={PhoneNumber} username={username} backToSMS={backToSMS} />
            <EmailEmptyModal open={emailEmpty} setOpen={setEmailEmpty} PhoneNumber={PhoneNumber} username={username} backToSMS={backToSMS} />

            <GenericDialog open={noFoundParent} setOpen={setNoFoundParents} iconSVG={cantFindSVG} height={'23rem'} showRopes={true}>
                <Container style={{ textAlign: "center" }}>
                    <Typography color="#064601" mt="1rem" variant="mediumBoldText">
                        {t('noFoundParentWithID')}
                    </Typography>
                    <Typography style={{ alignItems: "center", textAlign: "center" }}>
                        {t("toId")}  <b>{errorDetails.username}</b>
                    </Typography>
                    <Typography style={{ alignItems: "center", textAlign: "center", direction: "rtl" }}>
                        {t("andPhone")} <b>{errorDetails.phone?.toString().slice(1) + '+'}</b>
                    </Typography>
                </Container>
                <VBox>
                    <Button className={classes.buttonGreen}
                        onClick={e => { setNoFoundParents(false); navigate('/register/fparent'); }}>
                        {t("continueToRegistration")}
                    </Button>
                    <Button className={classes.buttonWhite} onClick={() => { setNoFoundParents(false); }}>
                        {t("tryAgain")}
                    </Button>
                </VBox>
            </GenericDialog>
            <GenericDialog open={smsSendError} setOpen={setSmsSendError} iconSVG={wrongSmsCode} height='21rem' width='32rem' showRopes={true}>
                <Typography color="#064601" mt="1rem" variant="mediumBoldText" textAlign="center">
                    {t('otherLoginMethod')}
                </Typography>
                <Typography color="#064601" mt="1rem" variant="mediumBoldText" textAlign="center">
                    {t('otherLoginMethod1')}
                </Typography>
                <Typography color="#064601" variant="mediumBoldText" textAlign="center">
                    {t('otherLoginMethod2')}
                </Typography>
                <Button
                    disabled={!PhoneNumber.phone || !username}
                    variant="contained"
                    onClick={e => handleLinkFromDialog()}
                    sx={{ marginTop: "0.5rem" }}
                    className={`${!PhoneNumber.phone || !username ? classes.buttonGreenLight : classes.buttonGreen} `}
                >
                    {t("sendCodeToMyEmail")}
                </Button>
            </GenericDialog>
            <GenericDialog open={emailSendedDialog} setOpen={setEmailSendedDialog} iconSVG={MailSuccess} height='22rem' width='33rem' showRopes={true}>
                <Typography color="#064601" mt="1rem" variant="mediumBoldText" textAlign="center">
                    {t('mailSended')}!
                </Typography>
                <Typography color="#064601" mt="0.5rem" variant="dialogBoldText" textAlign="center">
                    {`${t('toEmailAddress')}-${emailMask}`}
                </Typography>
                <Typography color="red" variant="dialogBoldText" textAlign="center">
                    {`${t('expairedLink')}-${t('fifteenMintues')}`}
                </Typography>
                <Typography color="#064601" variant="dialogBoldText" textAlign="center">
                    {t('sendAgain')}
                </Typography>
            </GenericDialog>
        </>
    );
}

const TabPanel = ({ children, value, index, ...other }) => {
    // ...other is refer t extra Box props
    const classes = useStyles();
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box height={'100%'} className={`${classes.BoxNoPaddingTopBottom}`} p={2}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

const ActionButtonRegister = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();
    const [language] = useGlobal((state) => state.language);

    return (
        <Box display='flex' flexDirection="column" >
            <Typography className={`${classes.fontBold} ${classes.CenterItems}`} style={{ fontSize: "1.3rem", color: '#000', direction: language === "he" ? "rtl" : "ltr" }} component="span">
                {t("firstUse")}
                <Typography className={`${classes.LinkStyle} ${classes.fontBold} ${classes.CenterItems}`} onClick={e => navigate('/register/fparent')} style={{ fontSize: "1.3rem" }}>
                    {t("toRegistration")}
                </Typography>
            </Typography>
        </Box>
    )
}

// const LoginVerification = () => {
//     return (
//         <div dir="LTR">
//             <div ref={ref => window.recaptchaWrapperRef = ref}>
//                 <div id="recaptcha-container"></div>
//             </div>
//         </div>
//     )
// }