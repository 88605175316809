// ----------------------------------------------------------------------

function pxToRem(value) {
    return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg, xl }) {
    return {
        '@media (max-width:400px)': { // 0-400
            fontSize: pxToRem(sm),
        },
        '@media (min-width:400px)': { // 400-600
            fontSize: pxToRem(md),
        },
        '@media (min-width:600px)': { // 600-900
            fontSize: pxToRem(lg),
        },
        '@media (min-width:900px)': { // 900-unlimited
            fontSize: pxToRem(xl),
        },

    };
}

const FONT_PRIMARY = 'Alef, sans-serif';

const typography = {
    fontFamily: FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    secondary: {
        fontSize: pxToRem(12),
        ...responsiveFontSizes({ sm: 11, md: 11, lg: 12, xl: 12 }),
    },
    activityTxt: {
        fontSize: pxToRem(11),
        ...responsiveFontSizes({ sm: 10, md: 13, lg: 16, xl: 18 }),
    },
    activityTxtBold: {
        fontSize: pxToRem(11),
        fontWeight: "bold",
        ...responsiveFontSizes({ sm: 10, md: 13, lg: 16, xl: 18 }),
    },
    activityLink: {
        color: '#333333 !important',
        textDecorationColor: '#333333 !important',
        fontSize: pxToRem(7),
        ...responsiveFontSizes({ sm: 6, md: 6, lg: 8, xl: 12 }),
    },
    activitySmallText: {
        fontSize: pxToRem(16),
        fontFamily: "Alef,sans-serif !important",
        ...responsiveFontSizes({ sm: 10.88, md: 12, lg: 12, xl: 14 }),
    },

    activityAlert: {
        color: '#BB0000 !important',
        textDecorationColor: '#BB0000 !important',
        fontWeight: "bold !important",
        fontSize: pxToRem(16),
        ...responsiveFontSizes({ sm: 12, md: 12, lg: 12, xl: 14 }),
    },
    regReqTxt: {
        textAlign: 'center',
        fontSize: pxToRem(11),
        ...responsiveFontSizes({ sm: 10, md: 13, lg: 16, xl: 18 }),
    },
    regReqMainTxt: {
        textAlign: 'center',
        fontWeight: "bold",
        fontSize: pxToRem(16),
        ...responsiveFontSizes({ sm: 12.8, md: 16, lg: 18, xl: 20 }),
    },
    regReqNameAct: {
        textAlign: 'center',
        fontWeight: "bold",
        fontSize: pxToRem(16),
        ...responsiveFontSizes({ sm: 14, md: 14, lg: 14, xl: 16 }),
    },
    greenText: {
        color: '#3A7735 !important',
        fontSize: pxToRem(16),
        ...responsiveFontSizes({ sm: 12, md: 12, lg: 16, xl: 18 }),
    },
    greenSmallText: {
        color: '#3A7735 !important',
        fontSize: pxToRem(12),
        ...responsiveFontSizes({ sm: 10, md: 10, lg: 12, xl: 12 }),
    },
    greenSmallLink: {
        color: '#3A7735 !important',
        fontSize: pxToRem(12),
        textDecoration: 'underline',
        ...responsiveFontSizes({ sm: 10, md: 10, lg: 12, xl: 12 }),
    },
    responsiveTextSize: {
        fontSize: pxToRem(16),
        ...responsiveFontSizes({ sm: 12.5, md: 13, lg: 14, xl: 16.5 }),
    },
    title: {
        fontSize: pxToRem(16),
        fontWeight: 'bold',
        ...responsiveFontSizes({ sm: 20, md: 24, lg: 28, xl: 30 }),
    },
    subTitle: {
        fontSize: pxToRem(16),
        ...responsiveFontSizes({ sm: 20, md: 20, lg: 20, xl: 22 }),
    },
    dialogBoldText: {
        fontWeight: 600,
        fontSize: pxToRem(16),
        ...responsiveFontSizes({ sm: 20, md: 20, lg: 20, xl: 22 }),
    },
    mediumBoldText: {
        fontWeight: 600,
        ...responsiveFontSizes({ sm: 16, md: 16, lg: 18, xl: 20 }),
    },
    smallText: {
        ...responsiveFontSizes({ sm: 12, md: 12, lg: 14, xl: 14 }),
    },
    mediumDarkGreyBoldRes: {
        color: '#333333 !important',
        fontWeight: "bold",
        ...responsiveFontSizes({ sm: 13, md: 20, lg: 20, xl: 21 }),
    },
    smallRedText: {
        color: '#bb0000 !important',
        ...responsiveFontSizes({ sm: 10, md: 12, lg: 14, xl: 14 }),
    },

    greenLargeBold: {
        color: '#3A7735 !important',
        fontSize: "1.5rem",
        fontWeight: "bold"
    },
    whiteLargeBold: {
        color: '#ffffff !important',
        fontSize: "1.5rem",
        fontWeight: "bold"
    },
    largeDarkGreyBold: {
        color: '#333333 !important',
        fontSize: "1.25rem",
        fontWeight: "bold"
    },
    mediumDarkGreyBold: {
        color: '#333333 !important',
        fontSize: "1.125rem",
        fontWeight: "bold"
    },
    mediumDarkGrey: {
        color: '#333333 !important',
        fontSize: "1.125rem"
    },
    normalDarkGreyBold: {
        color: '#333333 !important',
        fontSize: "1rem",
        fontWeight: "bold"
    },
    normalDarkGrey: {
        color: '#333333 !important',
        fontSize: "1rem"
    },
    greenSubTitle: {

    }
};

export default typography;
