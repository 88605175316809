import { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate } from "raviger";
import ReactCodeInput from 'react-verification-code-input';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier, EmailAuthProvider, linkWithCredential } from "firebase/auth";
import { Box, Link, Typography, Card, Container, Button } from '@mui/material';

import ReLinkCounter from '../Common/ReLinkCounter.jsx';
import { useStyles } from '../../assets/LoginStyle.jsx'
import { VBox } from '../../assets/SharedComponents';
import { useSnackbar } from 'notistack';
import useFeathers from '../../hooks/useFeathers.js';
import { RegisterContext } from 'context/registerContext.js';
import { patchUserAPI, generateVerificationEmail } from 'api/api.js';
import { useTranslation } from 'react-i18next';
import useVerification from 'hooks/useVerification.js';
import { CustomBusy } from 'Components/Common/CustomBusy.jsx';
import getSMS from '../../assets/getSMS.svg'
import wrongSmsCode from '../../assets/wrongSmsCode.svg'

export const SmsVerification = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const auth = getAuth();
    const { userObject } = useContext(RegisterContext)
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const hookVerification = useVerification();

    //bodyText
    const [wrongPinInput, setwrongPinInput] = useState(false);
    const [VerificationState, setVerificationState] = useState({ value: '' });
    const [verProperties, setverProperties] = useState({ Resending: false, BodyText: '', ResendingBodyText: '' });
    const [busy, setBusy] = useState(false);
    const [visible, setVisible] = useState(false)
    const feathers = useFeathers()
    const pinInputRef = useRef(null);

    useEffect(() => {
        if (userObject.phone) {
            updatePageText();
            sendCode();
        }
        else {
            navigate('/fparent');
        }
    }, []);
    const updatePageText = () => {
        const phoneText = `0${userObject.phone}`
        const SmsResendingBodyText = `${t("resendingCodeToPhone")}`;
        const SmsBodyText = `${t("sendingCodeToPhone")}`;
        setverProperties({ Resending: false, BodyText: SmsBodyText, ResendingBodyText: SmsResendingBodyText, phoneText: phoneText });
    }
    const sendCode = () => {
        try {
            if (!auth) {
                console.error("❌ Firebase Auth is not initialized.");
                return;
            }

            // ✅ Ensure reCAPTCHA container exists, recreate if necessary
            if (window.recaptchaVerifier) {
                try {
                    window.recaptchaVerifier.clear(); // Properly clear existing instance
                } catch (error) {
                    console.warn("⚠ reCAPTCHA already cleared, continuing...");
                }
                window.recaptchaVerifier = null;
            }

            // ✅ Reset reCAPTCHA container before reinitializing
            const recaptchaWrapper = document.getElementById("recaptcha-wrapper");
            if (recaptchaWrapper) {
                recaptchaWrapper.innerHTML = `<div id="recaptcha-container"></div>`; // Reset container
            }

            // ✅ Create new reCAPTCHA instance (auth as the first parameter)
            window.recaptchaVerifier = new RecaptchaVerifier(
                auth, // ✅ CORRECTED: auth should be the first parameter
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: (response) => {
                        console.log("✅ reCAPTCHA Verified:", response);
                    },
                }
            );

            const appVerifier = window.recaptchaVerifier;
            setBusy(true);

            // ✅ Send OTP via Firebase Auth
            signInWithPhoneNumber(auth, `+972${userObject.phone}`, appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                })
                .catch((error) => {
                    const msg = hookVerification.parseFirebaseError(error);
                    enqueueSnackbar(msg, { className: classes.redBG });
                })
                .finally(() => {
                    setBusy(false);
                });
        } catch (error) {
            console.error("❌ Error in sendCode:", error);
        }
    };

    const handleResending = () => {
        setverProperties({ ...verProperties, Resending: true });
        setVisible(false)
        sendCode();
        setwrongPinInput(false);
    };
    const handleVerificationChange = async (value) => {

        setVerificationState({ ...VerificationState, value: value });
        setBusy(true);
        window.confirmationResult.confirm(value).then(async () => {
            setwrongPinInput(false);
            if (!auth.currentUser.email)
                linkWithEmailAndPassword()
            const user = await feathers.authenticationWithToken('firebase', auth.currentUser.accessToken, auth.currentUser.emailVerified);
            const userData = {
                email: userObject.email,
                username: userObject.id.toString(),
                usernameType: userObject.idType,
                password: userObject.password,
                phone_number: `+972${userObject.phone}`,
                maritalStatus: userObject.familyStatus,
                firstName: userObject.firstName,
                ApprInfo: 'N',
                lastName: userObject.lastName,
                street: userObject.street,
                streetNo: userObject.streetNo,
                parent2Id: userObject.parent2Id,
                parent2IdType: userObject.parent2IdType,
                parent2Phone: userObject.parent2Phone
            };
            await patchUserAPI(user._id, userData);
            // update user
            generateVerificationEmail({ email: userObject.email });
            navigate(`/mailver/${userObject.email}`);
        }).catch((error) => {
            const msg = hookVerification.parseFirebaseError(error);
            enqueueSnackbar(msg, { className: classes.redBG });
            setVerificationState({ ...VerificationState, value: '' });
            setwrongPinInput(true);
        }).finally(() => {
            setBusy(false);
            try { pinInputRef.current.__clearvalues__(); }
            catch (error) {
            }
        });
    };
    const linkWithEmailAndPassword = () => {
        const emailCredential = EmailAuthProvider.credential(userObject.email, userObject.password);
        linkWithCredential(auth.currentUser, emailCredential).then((userCredential) => {
        }).catch((error) => {
            console.log("Account linking error", error);
            const msg = hookVerification.parseFirebaseError(error);
            enqueueSnackbar(msg, { className: classes.redBG });
        });

    }


    return (
        <Box display="flex" flexDirection="column" alignItems="center" flexWrap="wrap" margin="1rem" >
            <Card className={classes.paperStyle}>
                <Container sx={{ overflow: "auto", padding: "24px" }}>
                    <VBox alignItems="center" sx={{ marginTop: "2rem" }}>
                        <Typography sx={{ fontSize: "2rem", fontWeight: "bold" }}> {t("phoneVer")} </Typography>
                        <img
                            alt={"..."}
                            width="200px"
                            height="120px"
                            src={getSMS}
                        />
                        {!wrongPinInput ?
                            <>
                                {!verProperties.Resending ?
                                    <Typography align="center" style={{ marginTop: "1rem", fontSize: "1.3rem" }} >
                                        {t("sendingCodeToPhone")} </Typography> :
                                    <Typography align="center" style={{ marginTop: "1rem", fontSize: "1.3rem" }} >
                                        {t("resendingCodeToPhone")}<span style={{ fontWeight: "bold" }}> {t("another")} </span>
                                    </Typography>}
                                <br></br>
                                <div style={{ direction: "ltr", marginTop: "1rem", fontSize: "1.3rem", fontWeight: "bold" }}> {`${t("toPhoneNum")} ${verProperties.phoneText}`}</div>
                                <Typography align="center" display="block" style={{ color: "#3a7735", marginTop: "1rem" }}>
                                    {t("pleaseEnterCode")}
                                </Typography>
                            </> :
                            <>
                                <img
                                    alt={"..."}
                                    width="200px"
                                    height="120px"
                                    src={wrongSmsCode}
                                />
                                <Typography align="center" className={`${classes.RedColor}`} >
                                    {t("wrongAuthCode")}
                                </Typography>
                                <Typography align="center" className={`${classes.paddingBottom1rem}`}>
                                    {t("reEnterCode")}
                                </Typography>
                            </>
                        }
                    </VBox>
                    {busy ? <CustomBusy /> :
                        <VBox alignItems="center" style={{ direction: 'ltr', marginTop: "2rem" }}>
                            <ReactCodeInput
                                autoComplete={false}
                                type='number'
                                inputMode="numeric"
                                autoFocus={true}
                                onComplete={handleVerificationChange}
                                fields={6}
                                ref={pinInputRef}
                                color='black'
                                className={classes.codePinInputStyle}
                                disabled={!!busy} />
                        </VBox>}
                    <VBox justifyContent={'space-around'}>
                        <VBox alignItems={"center"} justifyContent={"center !important"} marginTop={"2rem"}>
                            {!visible ? <ReLinkCounter setVisible={setVisible} /> :
                                <Link color={'#007f00'} onClick={handleResending} className={`${classes.CenterItems} ${classes.LinkStyle} ${classes.paddingBottom1rem}`}>
                                    {t("noReceiveToken")}
                                </Link>}
                            {/* <Typography sx={{ color: "#ff0000", textAlign: "center", fontSize: "0.7rem" }}>ללקוחות פרטנר ישנה תקלה זמנית בקבלת קוד אימות בהודעות sms</Typography>
                            <Typography sx={{ color: "#ff0000", textAlign: "center", fontSize: "0.75rem" }}>כדי להתחבר לפורטל יש ללחוץ על הכפתור לא קיבלתי sms</Typography> */}
                        </VBox>
                    </VBox>
                    <Box display="flex" flexDirection="row" justifyContent="left">
                        <Button onClick={e => window.history.back()} className={classes.buttonWhite}>{t("notYourPhone")}</Button>
                    </Box>
                </Container>
            </Card>

            <div ref={ref => window.recaptchaWrapperRef = ref}>
                <div id="recaptcha-container"></div>
            </div>

        </Box>


    );

}