import './App.css';
import React from "react";
import { initializeApp } from "firebase/app";
import Router from './router/Router';
import ThemeProvider from './theme';
import { SnackbarProvider } from 'notistack';
import { firebaseConfig } from "./api/client";
import { getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import useAnalytics from './hooks/useAnalytics';
import useAccessibility from 'hooks/useAccessibility';
import { system } from "./api/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function App() {
  const hookAnalytics = useAnalytics();
  const hookAccessibility = useAccessibility();
  const RECAPTCHA_SITE_KEY = process.env[`REACT_APP_RECAPTCHA_SITE_KEY_${system.toUpperCase()}`];

  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", "rtl");
  }, []);

  let gaTrackingCode = process.env[`REACT_APP_GA_TRACKING_${system.toUpperCase()}`];
  if (gaTrackingCode) {
    hookAnalytics.initialize(gaTrackingCode);
  }

  try {
    window.self === window.top && hookAccessibility.initialize();
  } catch (error) {
    console.log(error);
  }

  // ✅ Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  // ✅ Proper App Check Setup (Auto for Prod, Debug for Dev)
  if (system === "prod") {
    initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider(RECAPTCHA_SITE_KEY),
      isTokenAutoRefreshEnabled: true, // ✅ Auto-refresh in Production
    });
  } else {
    // ✅ Enable Debug Token for Dev/QA (Manually Register in Firebase Console)
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider(RECAPTCHA_SITE_KEY),
      isTokenAutoRefreshEnabled: true,
      forceRefresh: true, // ✅ Allow forced refresh in Dev/QA
    });
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <ThemeProvider>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Router />
        </SnackbarProvider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;