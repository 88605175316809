import { TextField, Box, Button, Container } from '@mui/material';
import { useStyles } from '../../assets/LoginStyle.jsx'
import { VBox } from '../../assets/SharedComponents';
import { useTranslation } from 'react-i18next';
import { CustomBusy } from '../Common/CustomBusy.jsx';
import { useRef } from 'react';

export const SmsTab = (props) => {
    const { handleVerificationProcess, changePhoneValue, PhoneNumber, username, setUsername, busy, loginUserByEmail } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const inputRef = useRef();
    const inputRef1 = useRef();

    return (
        <Box height={'100%'} className={classes.width100} display='inline-grid' flexDirection='column'>
            <Container>
                <VBox className={`${classes.paddingBottom1rem} ${classes.paddingTop2_5rem} `} width="17rem">
                    <TextField
                        type="number"
                        name="Id"
                        style={{ width: "100% !important" }}
                        placeholder={t("idPlaceholder")}
                        className={`${classes.paddingBottom1rem} ${classes.RemoveTextFieldNumberArrow} ${classes.TextfieldILabelStyle}`}
                        InputProps={{
                            inputRef: inputRef,
                            inputProps: {
                                inputMode: 'tel',
                                pattern: "[0-9]*"
                            }
                        }}
                        onWheel={(e) => {
                            if (inputRef && inputRef.current && inputRef.current.blur) {
                                inputRef.current.blur();
                            }

                        }}
                        label={t("parentIdDoc")}
                        onChange={e => setUsername(e.target.value)}
                        value={username}
                        variant="outlined"
                        disabled={busy}
                    />

                    <TextField
                        placeholder={t("matchPhonePlaceholder")}
                        style={{ width: "100% !important" }}
                        className={`${classes.paddingBottom1rem} ${classes.RemoveTextFieldNumberArrow} ${classes.TextfieldILabelStyle}`}
                        InputProps={{
                            inputProps: {
                                inputMode: 'tel',
                                pattern: "[0-9]*"
                            },
                            maxLength: 20,
                            type: "number",
                            inputRef: inputRef1
                        }}
                        onWheel={(e) => {
                            if (inputRef1 && inputRef1.current && inputRef1.current.blur) {
                                inputRef1.current.blur();
                            }

                        }}
                        label={t("phoneNumber")}
                        onChange={e => changePhoneValue(e, 'phone')}
                        value={PhoneNumber.phone}
                        disabled={busy}
                        variant="outlined"
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleVerificationProcess(e);
                            }
                        }}
                    />

                    {busy ? <CustomBusy /> :
                        <>
                            <Button
                                disabled={!PhoneNumber.phone || !username}
                                variant="contained"
                                onClick={e => handleVerificationProcess(e)}
                                sx={{ marginTop: "2.5rem" }}
                                className={`${!PhoneNumber.phone || !username ? classes.buttonGreenLight : classes.buttonGreen} `}
                            >
                                {t("sendCodeToMyMobile")}
                            </Button>
                            <Button
                                disabled={!PhoneNumber.phone || !username}
                                variant="contained"
                                onClick={e => loginUserByEmail(e)}
                                sx={{ marginTop: "0.5rem" }}
                                className={`${!PhoneNumber.phone || !username ? classes.buttonGreenLight : classes.buttonGreen} `}
                            >
                                {t("sendCodeToMyEmail")}
                            </Button>
                        </>
                    }
                </VBox>
            </Container>
        </Box >
    );
}