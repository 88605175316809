import React from 'react'
import { Box } from '@mui/material';
import { useStyles } from '../../assets/LoginStyle.jsx'
import { SmsVerification } from '../../Components/SmsVerification';
import { SmsTab } from '../../Components/Login/SmsTab';
import { MySlider } from '../../Components/Common/MySlider';

export const SmsProcess = (props) => {
    const { handleNoMatchingParentFound, handleVerificationProcess, changePhoneValue, PhoneNumber,
        Verification, containerSlideRef, username, setUsername, busy, loginUserByEmail } = props;
    const classes = useStyles();

    return (
        <Box height={'100%'} justifyContent="center" display="flex" >
            {Verification.level === 1 &&
                <SmsTab
                    handleVerificationProcess={handleVerificationProcess}
                    PhoneNumber={PhoneNumber}
                    changePhoneValue={changePhoneValue}
                    username={username}
                    setUsername={setUsername}
                    busy={busy}
                    className={classes.height100}
                    loginUserByEmail={loginUserByEmail}
                />
            }
            {/* Verification Code */}
            {Verification.level === 2 &&
                <MySlider CurrentLevel={Verification.level === 2} container={containerSlideRef.current}>
                    <SmsVerification
                        type="smsLogin"
                        PhoneNumber={PhoneNumber}
                        username={username}
                        changePhoneValue={changePhoneValue}
                        handleNoMatchingParentFound={handleNoMatchingParentFound} />
                </MySlider>}
        </Box>


    );
}